<template>
  <div
    v-for="menuItem in menuItems"
    :key="menuItem.url"
    class="subbox"
    v-bind:class="{ 'sub-opened': openedMenu === menuItem.name }"
  >
    <router-link
      v-if="menuItem.url === null && menuItem.subMenus !== undefined"
      :to="menuItem.subMenus[menuItem.open !== undefined ? menuItem.open : 0]?.url"
      class="nav_link subbox"
      v-on:click="openedMenu = menuItem.name"
    >
      <BaseIcon :name="menuItem.image" />
      <span class="nav_name">{{ menuItem.name }}</span>
    </router-link>
    <router-link
      v-else-if="menuItem.url"
      :to="menuItem.url"
      class="nav_link subbox"
      v-bind:class="{ active: currentRouteName === menuItem.url }"
      v-on:click="openedMenu = menuItem.name;selected()"
    >
      <BaseIcon :name="menuItem.image" />
      <span class="nav_name">{{ menuItem.name }}</span>
    </router-link>
    <router-link
      v-for="subMenuItem in menuItem.subMenus"
      :key="subMenuItem.url"
      :to="subMenuItem.url"
      class="nav_link sub_nav"
      v-on:click="selected"
      v-bind:class="{ active: currentRouteName === subMenuItem.url }"
    >
      <BaseIcon :name="subMenuItem.image" />
      <span class="nav_name">{{ subMenuItem.name }}</span>
    </router-link>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));
export default {
  name: "Submenu",
  components: { BaseIcon },
  props: {
    menuItems: Array,
  },
  data() {
    return {
      openedMenu: "/",
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  emits: ["selected"],
  methods: {
    selected: function () {
      this.$emit("selected");
    },
  },
};
</script>
