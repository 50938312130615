<template>
  <LeftSidebar activeMenu="Home" />
  <RightSidebar />
  <TopBar />
  <transition-page>
    <router-view />
  </transition-page>
</template>

<script>
import TopBar from "../components/TopBar";
import TransitionPage from "../transitions/TransitionPage";
import RightSidebar from "../components/RightSidebar";
import LeftSidebar from "../components/LeftSidebar";

export default {
  name: "Layout",
  components: {
    RightSidebar,
    LeftSidebar,
    TransitionPage,
    TopBar,
  },
};
</script>