<template>
  <div
    class="r-navbar"
    :class="{ 'show-phone': showMenu, show: show }"
    id="nav-bar-2"
  >
    <nav class="nav">
      <div>
        <div class="main-scroll">
          <button
            v-on:click="logout()"
            :title="$t(`rightSidebar.logout`)"
            class="nav_logo d-md-block d-none"
          >
            <span
              class="prof-image"
              :style="'background: url(\'' + user?.avatar_url + '\')'"
            >
              <BaseIcon class="logout" name="logout" />
            </span>
          </button>
          <div class="nav_list">
            <button
              v-for="(button, i) in defaultButtons"
              class="nav_link"
              :class="{ active: show }"
              v-on:click="loadComponent(i, true)"
              :key="i"
            >
              <span class="rounded-box">
                <BaseIcon :name="button.icon" />
              </span>
              <span class="nav_name">{{ button.title }}</span>
            </button>
            <hr />
            <button
              v-for="(button, i) in buttons"
              class="nav_link active"
              v-on:click="loadComponent(i)"
              :key="i"
            >
              <span class="rounded-box">
                <BaseIcon :name="button.icon" />
              </span>
              <span class="nav_name">{{ button.title }}</span>
            </button>
          </div>
          <button class="nav_link menu-toggler">
            <span class="rounded-box">
              <BaseIcon name="round-plus" color="#713ae8" />
            </span>
          </button>
        </div>
      </div>
    </nav>
    <div class="right-content" v-if="show">
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn-close"
          :aria-label="$t(`orderChange.close`)"
          v-on:click="close"
        ></button>
      </div>
      <div class="mt-5">
        <keep-alive>
          <component :is="currentTabComponent" :appId="appId"></component>
        </keep-alive>
      </div>
    </div>
  </div>

  <div
    v-if="activePanel && !show"
    ref="modal"
    class="modal right fade"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="rounded-box">
            <BaseIcon :name="activePanel.icon" />
          </div>
          <h5 class="modal-title">{{ activePanel.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <keep-alive>
            <component :is="currentTabComponent" :appId="appId"></component>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("./icons/BaseIcon.vue"));
import http from "../modules/http";
import externalComponent from "../modules/externalComponent";

export default {
  name: "RightSidebar",
  components: { BaseIcon },
  created() {
    this.addApps();
  },
  data() {
    return {
      store: useStore(),
      show: false,
      previ: null,
      currentTabComponent: null,
      activePanel: null,
      buttons: [],
      defaultButtons: [],
      myModal: null,
      appId: null,
    };
  },
  computed: {
    showMenu() {
      return this.store.state.showRightMenu;
    },
    defaultApps() {
      return this.store.state.defaultApps;
    },
    apps() {
      return this.store.state.apps;
    },
    appsCount() {
      return this.defaultApps.length + this.apps.length;
    },
    user() {
      return this.store.state.user;
    },
    appVersion() {
      return this.store.state.appVersion;
    },
  },
  watch: {
    appsCount() {
      this.addApps();
    },
    show() {
      if (this.show) {
        document.getElementById("body-pd").classList.add("body-pd-right");
      } else {
        document.getElementById("body-pd").classList.remove("body-pd-right");
      }
    },
  },
  methods: {
    logout() {
      http.fetch("/auth/log-out").then(() => {
        this.$store.commit("logout");
        this.$router.push("/login");
      });
    },
    addApps() {
      this.defaultButtons = [];
      const defaultApps = this.defaultApps;
      for (const key in defaultApps) {
        if (defaultApps[key].sidebarComponents !== undefined) {
          for (const compKey in defaultApps[key].sidebarComponents) {
            defaultApps[key].sidebarComponents[compKey].app =
              defaultApps[key].name;
            defaultApps[key].sidebarComponents[compKey].id =
              defaultApps[key].id;
            this.defaultButtons.push(
              defaultApps[key].sidebarComponents[compKey]
            );
          }
        }
      }

      this.buttons = [];
      const apps = this.apps;
      for (const key in apps) {
        if (apps[key].sidebarComponents !== undefined) {
          for (const compKey in apps[key].sidebarComponents) {
            apps[key].sidebarComponents[compKey].app = apps[key].name;
            apps[key].sidebarComponents[compKey].id = apps[key].id;
            this.buttons.push(apps[key].sidebarComponents[compKey]);
          }
        }
      }
    },
    close() {
      this.previ = null;
      this.show = false;
    },
    loadComponent(i, defaultApp = false) {
      this.activePanel = defaultApp ? this.defaultButtons[i] : this.buttons[i];

      if (this.myModal !== null) {
        this.myModal.hide();
      }

      if (this.previ === i) {
        this.previ = null;
        this.show = false;
        return;
      }

      this.previ = i;
      /* this.currentTabComponent = defineAsyncComponent(() =>
        import(
          `../apps/${this.activePanel.app}/sidebar/${this.activePanel.name}.vue`
        )
      );*/

      const _this = this;

      externalComponent(
        `/${this.activePanel.app}/sidebar/${this.activePanel.name}.umd.min.js?i=${this.appVersion}`
      ).then((MyComponent) => {
        _this.currentTabComponent = MyComponent;
        _this.appId = this.activePanel.id;
        _this.show = true;

        _this.$store.commit("toggleRightMenu");
        /*
        if (_this.show) {
          setTimeout(function () {
            _this.myModal = new bootstrap.Modal(_this.$refs.modal);
            _this.myModal.show();
            _this.$refs.modal.addEventListener("hidden.bs.modal", function () {
              _this.show = false;
            });
          }, 100);
        }
         */
      });
    },
  },
};
</script>
