<template>
  <div class="input-group mb-1" v-if="!onlyPicker">
    <input
      v-if="onlyOne"
      type="text"
      class="form-control"
      v-on:focus="show = true"
      :value="selectDateText[0]"
    />
    <input
      v-else
      type="text"
      class="form-control"
      v-on:focus="show = true"
      :value="selectDateText[0] + ' - ' + selectDateText[1]"
    />
  </div>
  <div class="date-picker-overlay" v-if="show" v-on:click="show = false"></div>
  <div class="date-picker" v-if="show">
    <div class="row">
      <div :class="{ 'col-9': !onlyOne, 'col-12': onlyOne }">
        <div class="row">
          <div
            :class="{ 'col-6': !onlyOne, 'col-12': onlyOne }"
            v-for="i of onlyOne ? 1 : 2"
            :key="i"
          >
            <div class="">
              <div class="mb-3">
                <span v-if="i === 1 && !onlyOne">{{ $t("datePicker.startDate") }}</span>
                <span v-else-if="!onlyOne">{{ $t("datePicker.endDate") }}</span>
                <input
                  v-show="!editInput[i - 1]"
                  type="text"
                  v-model="selectDateText[i - 1]"
                  v-on:focus="showInput(i - 1)"
                />
                <input
                  type="date"
                  data-date-format="YYYY-MM-DD"
                  v-model="selectDateText2[i - 1]"
                  :ref="'input' + (i - 1)"
                  v-show="editInput[i - 1]"
                  v-on:focusout="hideInput(i - 1)"
                />
                <input
                  v-if="showTime"
                  v-model="selectTimeText[i - 1]"
                  v-on:focusout="setTime(i - 1)"
                  type="time"
                />
              </div>
              <div class="date-picker-top">
                <button v-if="i === 1" v-on:click="prevMonth" class="prev-btn">
                  &larr;
                </button>
                <b>{{ months[navDate[i - 1].getMonth()] }}</b>
                {{ navDate[i - 1].getFullYear() }}
                <button
                  v-if="i === 2 || onlyOne"
                  v-on:click="nextMonth"
                  class="next-btn"
                >
                  &rarr;
                </button>
              </div>
            </div>
            <div class="days">
              <div v-for="day of days" :key="day">{{ day }}</div>
              <div v-for="day of firstDay[i - 1]" :key="day"></div>
              <button
                v-for="day of lastDay[i - 1]"
                :class="{
                  end: isEnd(day, i - 1),
                  range: isInRange(day, i - 1),
                }"
                :disabled="isSelectable(day, i - 1)"
                v-on:click="setSelectDate(day, i - 1)"
                :key="day"
              >
                {{ day }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3" v-if="!onlyOne">
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.today") }}</span>
          <input type="radio" v-model="fixValue" value="now" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.yesterday") }}</span>
          <input type="radio" v-model="fixValue" value="1" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.last7Days") }}</span>
          <input type="radio" v-model="fixValue" value="7" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.last14Days") }}</span>
          <input type="radio" v-model="fixValue" value="14" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.last30Days") }}</span>
          <input type="radio" v-model="fixValue" value="30" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.thisMonth") }}</span>
          <input type="radio" v-model="fixValue" value="month" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.thisYear") }}</span>
          <input type="radio" v-model="fixValue" value="year" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.lifetime") }}</span>
          <input type="radio" v-model="fixValue" value="36525" /><span
            class="checkmark mx-2"
          ></span>
        </label>
        <label class="cstm-check dropdown-item">
          <span class="text-black mx-2 regular-14">{{ $t("datePicker.custom") }}</span>
          <input type="radio" v-model="fixValue" value="custom" disabled /><span
            class="checkmark mx-2"
          ></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import date from "../../modules/date";

export default {
  name: "DatePicker",
  props: {
    modelValue: Array,
    showPicker: {
      default: false,
      type: Boolean,
    },
    showTime: {
      default: false,
      type: Boolean,
    },
    onlyInRange: Number,
    onlyPicker: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      inString: false,
      onlyOne: false,
      show: this.showPicker,
      selectDate: [new Date(), new Date()],
      maxDate: new Date(),
      selectDateText: ["", ""],
      selectDateText2: ["", ""],
      selectTimeText: ["", ""],
      fixValue: "custom",
      navDate: [new Date(), new Date()],
      editInput: [false, false],
      firstDay: [0, 0],
      lastDay: [0, 0],
      select: 0,
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  beforeMount() {
    date.setLocale(this.locale);
    if (
      this.modelValue !== undefined &&
      this.modelValue !== null &&
      (typeof this.modelValue !== "string" || this.modelValue.length > 5)
    ) {
      if (
        typeof this.modelValue.length !== "undefined" &&
        typeof this.modelValue !== "string"
      ) {
        this.selectDate[0] = this.modelValue[0];
        this.selectDate[1] = this.modelValue[1];
        if (
          this.selectDate[0].getMonth() === this.selectDate[1].getMonth() &&
          this.selectDate[0].getFullYear() === this.selectDate[1].getFullYear()
        ) {
          let date = new Date(this.selectDate[0]);
          this.navDate[0] = date;
          date = new Date(this.selectDate[1]);
          date.setMonth(date.getMonth() + 1);
          this.navDate[1] = date;
        } else {
          let date = new Date(this.selectDate[1]);
          this.navDate[1] = date;
          date = new Date(this.selectDate[1]);
          date.setMonth(date.getMonth() - 1);
          this.navDate[0] = date;
        }
      } else {
        this.onlyOne = true;
        if (typeof this.modelValue === "string") {
          this.selectDate[0] = new Date(
            this.modelValue
              .replace(/\./g, "-")
              .replace("T", " ")
              .substring(0, 19)
          );
          this.inString = true;
        } else {
          this.selectDate[0] = this.modelValue;
        }
        this.selectDate[1] = this.selectDate[0];

        let date = new Date(this.selectDate[0]);
        this.navDate[0] = date;
        date = new Date(this.selectDate[1]);
        date.setMonth(date.getMonth() + 1);
        this.navDate[1] = date;
      }
    } else {
      if (
        typeof this.modelValue === "string" ||
        this.modelValue === undefined ||
        this.modelValue === null
      ) {
        this.onlyOne = true;
        this.inString = true;
      }
      for (let i = 0; i < 2; i++) {
        this.navDate[i].setMonth(this.navDate[i].getMonth() + i);
      }
    }
    this.setCalendar();
  },
  emits: ["update:modelValue", "update:showPicker", "change"],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    selectedDateHelp() {
      return this.selectDate[0] + this.selectDate[1];
    },
  },
  watch: {
    locale() {
      date.setLocale(this.locale);
      this.months = date.getMonths();
      this.setTextDates();
    },
    showPicker() {
      this.show = this.showPicker;
    },
    show() {
      this.$emit("update:showPicker", this.show);
      if (this.onlyPicker) {
        if (this.onlyOne) {
          if (this.inString) {
            this.$emit(
              "update:modelValue",
              this.selectDate[0]
                .toISOString()
                .replace("T", " ")
                .substring(0, this.selectDate[0].toISOString().indexOf("."))
            );
          } else {
            this.$emit("update:modelValue", this.selectDate[0]);
          }
        } else {
          this.$emit("update:modelValue", [
            this.selectDate[0],
            this.selectDate[1],
          ]);
        }
      }
      this.$emit("change");
    },
    fixValue() {
      let date = new Date();
      if (this.fixValue === "now") {
        this.selectDate[0] = date;
        this.selectDate[1] = date;
      } else if (this.fixValue === "month") {
        this.selectDate[1] = date;
        date = new Date();
        date.setDate(1);
        this.selectDate[0] = date;
      } else if (this.fixValue === "year") {
        this.selectDate[1] = date;
        date = new Date();
        date.setDate(1);
        date.setMonth(0);
        this.selectDate[0] = date;
      } else if (Number.isInteger(this.fixValue * 1)) {
        this.selectDate[1] = date;
        date = new Date();
        date.setDate(date.getDate() - this.fixValue);
        this.selectDate[0] = date;
      }

      if (this.onlyOne) {
        if (this.inString) {
          this.$emit(
            "update:modelValue",
            this.selectDate[0]
              .toISOString()
              .replace("T", " ")
              .substring(0, this.selectDate[0].toISOString().indexOf("."))
          );
        } else {
          this.$emit("update:modelValue", this.selectDate[0]);
        }
      } else {
        this.$emit("update:modelValue", [
          this.selectDate[0],
          this.selectDate[1],
        ]);
      }
    },
    selectedDateHelp() {
      this.setTextDates();
    },
  },
  methods: {
    setTextDates() {
      for (let i = 0; i < 2; i++) {
        this.selectDateText[i] = date.formatFullName(this.selectDate[i]);

        /*
        this.selectDateText[i] =
          this.months[this.selectDate[i].getMonth()] +
          " " +
          this.selectDate[i].getDate() +
          ", " +
          this.selectDate[i].getFullYear();
         */
        this.selectDateText2[i] =
          this.selectDate[i].getFullYear() +
          "-" +
          (this.selectDate[i].getMonth() < 9 ? "0" : "") +
          (this.selectDate[i].getMonth() + 1) +
          "-" +
          (this.selectDate[i].getDate() < 10 ? "0" : "") +
          this.selectDate[i].getDate();

        this.selectTimeText[i] =
          (this.selectDate[i].getHours() < 9 ? "0" : "") +
          this.selectDate[i].getHours() +
          ":" +
          (this.selectDate[i].getMinutes() < 9 ? "0" : "") +
          this.selectDate[i].getMinutes();
      }
    },
    showInput(i) {
      const _this = this;
      this.editInput[i] = true;
      setTimeout(() => {
        _this.$refs["input" + i].focus();
      }, 100);
    },
    setTime(i) {
      const dpart = this.selectTimeText[i].split(":");
      this.selectDate[i].setHours(dpart[0]);
      this.selectDate[i].setMinutes(dpart[1]);

      if (this.inString) {
        this.$emit(
          "update:modelValue",
          this.selectDate[0]
            .toISOString()
            .replace("T", " ")
            .substring(0, this.selectDate[0].toISOString().indexOf("."))
        );
      } else {
        this.$emit("update:modelValue", this.selectDate[0]);
      }
    },
    hideInput(i) {
      this.editInput[i] = false;
      const dpart = this.selectDateText2[i].split("-");
      const date = new Date();
      date.setMonth(dpart[1] - 1);
      date.setDate(dpart[2]);
      date.setFullYear(dpart[0]);
      this.selectDate[i] = date;
      if (this.selectDate[0] > this.selectDate[1]) {
        const tmp = this.selectDate[1];
        this.selectDate[1] = this.selectDate[0];
        this.selectDate[0] = tmp;
      }
      this.fixValue = "custom";
    },
    isEnd(day, i) {
      const month1 = new Date(this.navDate[i]);
      month1.setDate(day);
      if (
        month1.getFullYear() === this.selectDate[0].getFullYear() &&
        month1.getMonth() === this.selectDate[0].getMonth() &&
        month1.getDate() === this.selectDate[0].getDate()
      ) {
        return true;
      } else if (
        month1.getFullYear() === this.selectDate[1].getFullYear() &&
        month1.getMonth() === this.selectDate[1].getMonth() &&
        month1.getDate() === this.selectDate[1].getDate()
      ) {
        return true;
      }
      return false;
    },
    isInRange(day, i) {
      const month1 = new Date(this.navDate[i]);
      month1.setDate(day);
      return this.selectDate[0] < month1 && month1 < this.selectDate[1];
    },
    isSelectable(day, i) {
      const month1 = new Date(this.navDate[i]);
      month1.setDate(day);
      if (this.onlyInRange === 1) {
        return month1 > this.maxDate;
      } else if (this.onlyInRange === 2) {
        return month1 < this.maxDate;
      }
      return false;
    },
    setSelectDate(day, i) {
      if (this.onlyOne) {
        const month1 = new Date(this.navDate[i]);
        month1.setDate(day);
        this.selectDate[0] = month1;
        this.selectDate[1] = month1;

        if (this.inString) {
          this.$emit(
            "update:modelValue",
            this.selectDate[0]
              .toISOString()
              .replace("T", " ")
              .substring(0, this.selectDate[0].toISOString().indexOf("."))
          );
        } else {
          this.$emit("update:modelValue", this.selectDate[0]);
        }
      } else if (this.select === 0) {
        const month1 = new Date(this.navDate[i]);
        month1.setDate(day);
        this.selectDate[0] = month1;
        this.selectDate[1] = month1;
        this.select++;
      } else {
        const month1 = new Date(this.navDate[i]);
        month1.setDate(day);
        this.selectDate[1] = month1;
        this.select = 0;
        if (this.selectDate[0] > this.selectDate[1]) {
          const tmp = this.selectDate[1];
          this.selectDate[1] = this.selectDate[0];
          this.selectDate[0] = tmp;
        }
        this.$emit("update:modelValue", this.selectDate);
      }
      this.fixValue = "custom";
    },
    nextMonth() {
      const date1 = new Date(this.navDate[0]);
      date1.setMonth(date1.getMonth() + 1);
      this.navDate[0] = date1;
      const date2 = new Date(date1);
      date2.setMonth(date2.getMonth() + 1);
      this.navDate[1] = date2;
      this.setCalendar();
    },
    prevMonth() {
      const date1 = new Date(this.navDate[0]);
      date1.setMonth(date1.getMonth() - 1);
      this.navDate[0] = date1;
      const date2 = new Date(date1);
      date2.setMonth(date2.getMonth() + 1);
      this.navDate[1] = date2;
      this.setCalendar();
    },
    setCalendar() {
      for (let i = 0; i < 2; i++) {
        const month1 = new Date(this.navDate[i]);
        month1.setDate(1);
        this.firstDay[i] = month1.getDay();

        month1.setMonth(month1.getMonth() + 1);
        month1.setDate(0);
        this.lastDay[i] = month1.getDate();
      }
    },
  },
};
</script>
<style>
.date-picker {
  position: absolute;
  background: #fff;
  padding: 1em;
  border-radius: 4px;
  z-index: 1001;
  box-shadow: 0 0 3px 1px rgb(131 131 131 / 30%);
}
.date-picker .days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.date-picker .days > * {
  text-align: center;
}
.date-picker button {
  background: transparent;
  border: none;
}
.date-picker .days .range {
  background: #d1d1d1;
}
.date-picker .days .end {
  background: #a256e0;
  color: #fff;
}
.date-picker-overlay {
  background: rgb(131 131 131 / 6%);
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.date-picker .date-picker-top {
  position: relative;
  text-align: center;
}
.date-picker .date-picker-top .next-btn {
  position: absolute;
  right: 0;
}
.date-picker .date-picker-top .prev-btn {
  position: absolute;
  left: 0;
}
</style>
